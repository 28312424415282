import { Injectable, inject } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { MessageService } from 'primeng/api';
import { switchMap, catchError, of, map, filter } from 'rxjs';

import { SearchService } from '@offconon/shared/features/generated-api';

import * as SearchActions from './search.actions';

@Injectable()
export class SearchEffects {
  private actions$ = inject(Actions);
  private searchService = inject(SearchService);
  private messageService = inject(MessageService);

  fetchKeywords$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.fetchKeywords),
      filter((action) => action.translations !== undefined && action.translations.length > 0),
      switchMap((action: ReturnType<typeof SearchActions.fetchKeywords>) =>
        this.searchService
          .searchKeywordList(action.page, action.pageSize, {
            business_id: action.businessId,
            language_id: action.languageId,
            type: action.serviceType,
            translations: action.translations,
          })
          .pipe(
            map((result) => {
              return result.results?.map((item) => item.translations?.[0].translation) as string[];
            }),
            map((search) => SearchActions.fetchKeywordsSuccess({ keyword: search })),
            catchError((error) => of(SearchActions.loadSearchFailure({ error }))),
          ),
      ),
    ),
  );

  createBulkKeyword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.createBulkKeyword),
      switchMap((action) =>
        this.searchService.searchBulkKeywordCreateToKeywordServiceCreate(action.keywordList).pipe(
          map((response) => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Keyword created successfully',
            });
            return SearchActions.createBulkKeywordSuccess({ response });
          }),
          catchError((error) => of(SearchActions.loadSearchFailure({ error }))),
        ),
      ),
    ),
  );

  fetchKeywordById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.fetchTranslations),

      switchMap((action) => {
        return this.searchService
          .searchKeywordtranslationList(1, 300, {
            id: action.searchParameters.universalLanguageTranslateid,
          })
          .pipe(
            map((user) =>
              // UserActions.onSuccessfullyFetchedUserList({
              //   userList: user?.results || ([] as any),
              // }),
              SearchActions.loadSearchFailure({ error: '' }),
            ),
            catchError((error) => of(SearchActions.loadSearchFailure({ error }))),
          );
      }),
    ),
  );
}
